import React, { useState } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import { StaticImage } from 'gatsby-plugin-image';
import loadable from '@loadable/component';
import { oldList, newList } from '@data/industries';

import HeaderComponent from '@components/molecules/header';
import MainTitleCard from '@components/molecules/main-title-card';
import Article from '@components/molecules/article';
import TextButton from '@components/atoms/text-button';
import Seo from '@components/molecules/seo';
import Divider from '@components/atoms/divider';
import Title from '@components/molecules/title';
import { firstList } from '@data/industries/roofing.js';
import OldVsNew from '@components/organisms/old-vs-new';
import IconCardList from '@components/organisms/icon-card-list';
import { apiUrl } from '@helpers';
import { container } from '@styles/main.module.scss';

import { productContainer, behindMask, scheduler, schedulerItem } from './scheduling.module.scss';

const Modal = loadable(() => import('@components/molecules/modal'));
const FooterComponent = loadable(() => import('@components/molecules/footer'));
const SubscribeBanner = loadable(() => import('@components/molecules/subscribe-banner'));

const Scheduling = () => {
  const Intl = useIntl();

  const [showDialog, setShowDialog] = useState(false);
  const openModal = () => setShowDialog(true);
  const closeModal = () => setShowDialog(false);
  const [values, setValues] = useState(null);

  const toggleDeleteInvite = (data) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: data.email }),
    };
    fetch(`${apiUrl}/delete-invite`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        closeModal();
        setValues(data);
        setTimeout(() => openModal(), 2000);
      });
  };

  const formSuccessState = (val) => {
    if (val?.action !== 'delete') {
      closeModal();
      setValues(val);
      setTimeout(() => {
        openModal();
      }, 500);
    } else {
      toggleDeleteInvite(val);
    }
  };

  return (
    <div className={`${container} ${productContainer}`}>
      <Modal
        close={closeModal}
        showDialog={showDialog}
        hasValues={values}
        onDelete={toggleDeleteInvite}
        setFormValues={(formValues) => formSuccessState(formValues)}
      />
      <Seo
        title={Intl.formatMessage({ id: 'pages.productOverview.metaTitle' })}
        description={Intl.formatMessage({ id: 'pages.productOverview.metaDescription' })}
      />
      <HeaderComponent headerStyle="pricingHeader" />
      <span className={behindMask} />
      <MainTitleCard
        maxParagraphWidth={760}
        title={Intl.formatMessage({ id: 'pages.productOverview.bannerTitle' })}
        subtitle={Intl.formatMessage({ id: 'pages.productOverview.bannerDescription' })}
      />
      <Divider className="style3" />
      <div className={`${scheduler}`}>
        <div className={`${schedulerItem}`}>
          <StaticImage
            src="../images/scheduler@2x.png"
            width={1226}
            height={699}
            alt="Scheduler"
            quality={96}
          />
        </div>
      </div>
      <Divider className="style2" />
      <TextButton
        h1={Intl.formatMessage({ id: 'pages.pricing.schedulerBanner' })}
        h2={Intl.formatMessage({ id: 'pages.pricing.schedulerBannerDesc' })}
        btnText={Intl.formatMessage({ id: 'pages.miscellaneous.startFreeTrial' })}
        onBtnClick={() => openModal()}
      />
      <Divider className="style2" />
      <OldVsNew oldList={oldList} newList={newList} />
      <Divider className="style3" />
      <Article
        isSwapped
        title="Frictionless shift trades and covers"
        description="Give your employees the flexibility they want. Rosteroo allows staff to trade shifts or find cover without having to make a single call. And do all of this on their mobile device."
        image="plumbing-one"
        maxWidth={348}
        imagePadding="6rem 0"
        imageWidth={348}
        imageHeight={393}
      />
      <Article
        title="In-depth shift details for easier planning"
        description="Shift start & end times, earnings, job position, location, break hours, notes and so much more. Get everything you need to plan for your next shift with Rosteroo’s shift details."
        image="plumbing-two"
        maxWidth={294}
        imagePadding="6rem 0"
        imageWidth={294}
        imageHeight={405}
      />
      <Article
        isSwapped
        title="Flexible scheduling with open shifts"
        description="Create flexible schedules or quickly fill in new, unexpected shifts. Open shifts allow the right staff members to easily pick up new shifts whenever you make them available."
        image="roofing-one"
        maxWidth={500}
        imagePadding="6rem 0"
        imageWidth={500}
        imageHeight={433}
      />
      <Divider className="style3" />
      <StaticImage
        src="../../../images/streamline-scheduling.png"
        width={1140}
        height={460}
        alt="Streamline Scheduling"
        quality={98}
      />
      <Divider />
      <Title
        title="Streamline the entire scheduling process"
        maxWidth={1000}
        maxDescriptionWidth={1000}
        description="You’ll never have to worry if all shifts are filled again. Rosteroo helps you stay on top of your team’s schedule without making a single call."
      />
      <Divider className="style3" />
      <IconCardList cardList={firstList} hasBigImages style="smallerMargin" />
      <Divider />
      <SubscribeBanner
        title={Intl.formatMessage({ id: 'pages.pricing.pricingSubscribeBanner' })}
        subtitle={Intl.formatMessage({ id: 'pages.pricing.subscribeBannerDesc' })}
        placeholder={Intl.formatMessage({ id: 'pages.miscellaneous.typeYourEmail' })}
        checkItemOne={Intl.formatMessage({ id: 'pages.miscellaneous.noCreditCard' })}
        checkItemTwo={Intl.formatMessage({ id: 'pages.miscellaneous.14DaysTrial' })}
        checkItemThree={Intl.formatMessage({ id: 'pages.miscellaneous.cancelAnytime' })}
      />
      <FooterComponent />
    </div>
  );
};

export default Scheduling;
