// extracted by mini-css-extract-plugin
export var btnDefault = "old-vs-new-module--btn-default--rnYbf";
export var attoiconJobs = "old-vs-new-module--attoicon-jobs--p2TQG";
export var attoiconNotes = "old-vs-new-module--attoicon-notes--iHgdP";
export var attoiconTimesheets = "old-vs-new-module--attoicon-timesheets--RLsnP";
export var attoiconTimesheetsRounded1 = "old-vs-new-module--attoicon-timesheets-rounded1--ucMct";
export var attoiconMobileTt = "old-vs-new-module--attoicon-mobile-tt--QxlcE";
export var attoiconNamedSites = "old-vs-new-module--attoicon-named-sites--W+Q-R";
export var attoiconProfitableBids = "old-vs-new-module--attoicon-profitable-bids--e7HiS";
export var attoiconDepartments = "old-vs-new-module--attoicon-departments--rqQdz";
export var attoiconTimeline = "old-vs-new-module--attoicon-timeline--34VtZ";
export var attoiconFilters = "old-vs-new-module--attoicon-filters--HMn6z";
export var attoiconVerified = "old-vs-new-module--attoicon-verified--+5orf";
export var attoiconTimezone = "old-vs-new-module--attoicon-timezone--EVgL5";
export var attoiconManualEntries = "old-vs-new-module--attoicon-manual-entries--4JmzG";
export var attoiconJobCosting = "old-vs-new-module--attoicon-job-costing--p32rU";
export var attoiconLocationTracking = "old-vs-new-module--attoicon-location-tracking--5UIn5";
export var attoiconBreak = "old-vs-new-module--attoicon-break--nnF5Y";
export var attoiconNightShift = "old-vs-new-module--attoicon-night-shift--0YxcT";
export var attoiconOvertime = "old-vs-new-module--attoicon-overtime--vneWs";
export var attoiconTimeTracking1 = "old-vs-new-module--attoicon-time-tracking1--ma1N0";
export var attoiconTimeTracking = "old-vs-new-module--attoicon-time-tracking--pMmQm";
export var attoiconLocationHistory = "old-vs-new-module--attoicon-location-history--7VnmE";
export var attoiconPrivacy = "old-vs-new-module--attoicon-privacy--XRuZv";
export var attoiconTimeEntryHistory = "old-vs-new-module--attoicon-time-entry-history--Ju5yr";
export var attoiconCustomize = "old-vs-new-module--attoicon-customize--s5h5e";
export var attoiconRoundedClock = "old-vs-new-module--attoicon-rounded-clock--0B8DS";
export var attoiconTimeOff = "old-vs-new-module--attoicon-time-off--uxeJ-";
export var attoiconNamedLocations = "old-vs-new-module--attoicon-named-locations--CL6Qp";
export var attoiconSandClock = "old-vs-new-module--attoicon-sand-clock--uVRq0";
export var attoiconBattery = "old-vs-new-module--attoicon-battery--mNqSg";
export var attoiconSupport = "old-vs-new-module--attoicon-support--ffE5E";
export var attoiconHelpSupport = "old-vs-new-module--attoicon-help-support--k3iSH";
export var attoiconWebTt = "old-vs-new-module--attoicon-web-tt--zD-Kh";
export var attoiconArchive = "old-vs-new-module--attoicon-archive--YH3mg";
export var attoiconEmail = "old-vs-new-module--attoicon-email--BNC55";
export var attoiconKiosk = "old-vs-new-module--attoicon-kiosk--Ojg1P";
export var attoiconShare = "old-vs-new-module--attoicon-share--YDUb5";
export var attoiconCrew = "old-vs-new-module--attoicon-crew--5d1zJ";
export var attoiconTeamActivity = "old-vs-new-module--attoicon-team-activity--Unq4I";
export var attoiconTeam = "old-vs-new-module--attoicon-team--ucKOq";
export var attoiconWages = "old-vs-new-module--attoicon-wages--90KqJ";
export var attoiconNotification = "old-vs-new-module--attoicon-notification--pVPdE";
export var attoiconAvatar = "old-vs-new-module--attoicon-avatar--cnOJQ";
export var attoiconViewMap = "old-vs-new-module--attoicon-view-map--WqHtF";
export var attoiconMovementTracking = "old-vs-new-module--attoicon-movement-tracking--yRTdm";
export var attoiconWageEstimates = "old-vs-new-module--attoicon-wage-estimates--cKX9U";
export var attoiconWageEstimatesBold = "old-vs-new-module--attoicon-wage-estimates-bold--2fIFv";
export var attoiconClose = "old-vs-new-module--attoicon-close--dkkw4";
export var attoiconPlus = "old-vs-new-module--attoicon-plus--drJlz";
export var attoiconMinus = "old-vs-new-module--attoicon-minus--RCCGR";
export var attoiconTick = "old-vs-new-module--attoicon-tick--mZrQW";
export var attoiconArrowLeft = "old-vs-new-module--attoicon-arrow-left--ZfWcE";
export var attoiconArrowRight = "old-vs-new-module--attoicon-arrow-right--lVUHg";
export var attoiconArrowDown = "old-vs-new-module--attoicon-arrow-down--mQ7FP";
export var attoiconArrowUp = "old-vs-new-module--attoicon-arrow-up--os+pO";
export var attoiconPlay = "old-vs-new-module--attoicon-play--41ejd";
export var attoiconLongArrow = "old-vs-new-module--attoicon-long-arrow--3JP9S";
export var titleContainer = "old-vs-new-module--titleContainer--ymSTD";
export var oldWayTitle = "old-vs-new-module--oldWayTitle--blxIB";
export var newWayTitle = "old-vs-new-module--newWayTitle---cnpO";
export var container = "old-vs-new-module--container--CitM8";
export var oldListStyle = "old-vs-new-module--oldListStyle--6vf0q";
export var newListStyle = "old-vs-new-module--newListStyle--J6ni0";