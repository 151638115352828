import React from 'react';
import PropTypes from 'prop-types';

import CheckCard from '@components/molecules/check-card';

import { container, oldListStyle, newListStyle } from './old-vs-new.module.scss';

const OldVsNew = ({ oldList, newList }) => (
  <div className={container}>
    <div className={oldListStyle}>
      {oldList?.map((title, idx) => (
        <CheckCard title={title} key={idx} />
      ))}
    </div>
    <div className={newListStyle}>
      {newList?.map((title, idx) => (
        <CheckCard title={title} key={idx} />
      ))}
    </div>
  </div>
);

OldVsNew.propTypes = {
  oldList: PropTypes.shape(),
  newList: PropTypes.shape(),
};

export default OldVsNew;
